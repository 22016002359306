const currencyFormat = (number, short = false) => {
  if (short) {
    return Number(number).toLocaleString("en", {
      currency: "USD",
    })
  }
  return Number(number).toLocaleString("en", {
    currency: "USD",
    style: "currency",
  })
}

export default currencyFormat
