import React from "react"
import ProgressDots from "../ProgressDots"

const SubmitButton = ({
  onClick,
  progress,
  disabled,
  label,
  id = "",
  fullWidth = false,
  className = "",
}) => {
  return (
    <button
      onClick={onClick}
      className={`button primary ${className} ${progress ? "progress" : ""} ${
        fullWidth ? "col-12" : ""
      }`}
      disabled={progress || disabled}
      id={id}
    >
      {label}
      <ProgressDots active={progress} />
    </button>
  )
}

export default SubmitButton
