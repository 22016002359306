import React, { useEffect, useState } from "react"
import * as styles from "./index.module.less"
import PriceSummaryTable from "@components/PriceSummaryTable"
import ConditionsList from "@components/ConditionsList"
import currencyFormat from "@utils/currencyFormat"
import { TeamSizeUl } from "../../../OnBoarding/TeamSizeSelector"
import countWorkingDays from "@utils/countWorkingDays"
import { differenceInHours, format, isSameDay, isWeekend } from "date-fns"
import nextMonday from "@utils/nextMonday"
import SubmitButton from "@components/SubmitButton"
import Datepicker from "./Datepicker"
import notifyError from "../../../../utils/notifyError"
import { useDispatch, useSelector } from "react-redux"
import { setValues } from "../../../../stores/ProfileStore/actions"
import request from "../../../../services/request"
import { today } from "../../../../utils/config"

const Index = () => {
  const [isDatepickerVisible, setIsDatepickerVisible] = useState(false)
  const [tsOpen, setTsOpen] = useState(false)
  const [progress, setProgress] = useState(false)

  const dispatch = useDispatch()
  const location = useSelector(state => state.profile.location)
  const startDate = useSelector(state => state.profile.startDate)
  const endDate = useSelector(state => state.profile.endDate)
  const itemUnit = useSelector(state => state.profile.itemUnit)
  const noOnDemandOptionsAvailable = useSelector(
    state => state.profile.noOnDemandOptionsAvailable,
  )
  const selectedOnDemandOption = useSelector(
    state => state.profile.selectedOnDemandOption,
  )
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)
  const onDemandDaysHoursCount = useSelector(
    state => state.profile.onDemandDaysHoursCount,
  )
  const holidays = useSelector(state => state.profile.holidays)
  const teamSize = useSelector(state => state.profile.teamSize)
  const startTime = useSelector(state => state.profile.startTime)
  const endTime = useSelector(state => state.profile.endTime)
  const TAX_LABEL = useSelector(state => state.profile.TAX_LABEL)
  const TAX_PERCENTAGE = useSelector(state => state.profile.TAX_PERCENTAGE)

  useEffect(() => {
    const customStartDate = isWeekend(new Date()) ? nextMonday() : today
    // if (!startDate) {
    //   dispatch(
    //     setValues({
    //       startDate: customStartDate,
    //       endDate: customStartDate,
    //     }),
    //   )
    // }
    search(customStartDate)
    window.addEventListener("click", () => {
      setIsDatepickerVisible(false)
      setTsOpen(false)
    })
    return () => {
      window.removeEventListener("click", () => {
        setIsDatepickerVisible(false)
        setTsOpen(false)
      })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (onDemandType === onDemandTypes[0] && startDate && !endDate) {
      dispatch(setValues({ endDate: startDate }))
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDatepickerVisible])

  const [count, setCount] = useState(teamSize)

  const [onDemandType] = useState("Daily")
  const onDemandTypes = ["Daily", "Hourly"]

  const [dateLabel, setDateLabel] = useState("")

  const [pricePerItem, setPricePerItem] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [hst, setHst] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (teamSize !== count) {
      setCount(teamSize)
      dispatch(
        setValues({
          selectedOnDemandOption: {},
          noOnDemandOptionsAvailable: false,
        }),
      )
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSize])

  useEffect(() => {
    if (!selectedOnDemandOption.id) return

    const price = isPerDayOnDemand
      ? selectedOnDemandOption.price_per_day
      : selectedOnDemandOption.price_per_hour
    setPricePerItem(price)
    setSubtotal(price * onDemandDaysHoursCount)
    setHst(price * onDemandDaysHoursCount * TAX_PERCENTAGE)
    setTotal(price * onDemandDaysHoursCount * (1 + TAX_PERCENTAGE))

    dispatch(
      setValues({
        total: price * onDemandDaysHoursCount * (1 + TAX_PERCENTAGE),
        subtotal: price * onDemandDaysHoursCount,
        teamSize: count,
        hst: price * onDemandDaysHoursCount * TAX_PERCENTAGE,
        pricePerItem: price,
      }),
    ) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOnDemandOption, onDemandDaysHoursCount])

  useEffect(() => {
    if (isPerDayOnDemand) {
      dispatch(setValues({ itemUnit: ["day", "days"] }))
      // only date range
      if (!startDate) setDateLabel("Start & End date")
      if (startDate && !endDate) {
        setDateLabel(`${format(startDate, "MMMM do")} - End date`)
      }
      if (startDate && endDate) {
        if (isSameDay(startDate, endDate)) {
          setDateLabel(`${format(startDate, "MMMM do")}`)
        } else {
          setDateLabel(
            `${format(startDate, "MMMM do")} - ${format(endDate, "MMMM do")}`,
          )
        }
      }
    } else {
      // date + times
      dispatch(setValues({ itemUnit: ["hour", "hours"] }))
      if (startDate) {
        setDateLabel(`${format(startDate, "MMMM do")}`)
      }
      if (startDate && startTime && endTime) {
        setDateLabel(
          `${format(startDate, "MMMM do")}, ${format(
            startTime,
            "h:mm a",
          )} - ${format(endTime, "h:mm a")}`,
        )
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, endTime])

  useEffect(() => {
    dispatch(
      setValues({
        onDemandDaysHoursCount: isPerDayOnDemand
          ? countWorkingDays(startDate, endDate, [])
          : differenceInHours(endTime, startTime),
      }),
    ) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, endTime])

  const search = async (customStartDate = null) => {
    // check if all the params are there
    if (!count) return
    if (isWeekend(startDate) || isWeekend(endDate)) {
      dispatch(
        setValues({
          noOnDemandOptionsAvailable: true,
        }),
      )
      return
    }
    if (!startDate && customStartDate && isWeekend(customStartDate)) {
      dispatch(
        setValues({
          noOnDemandOptionsAvailable: true,
        }),
      )
      return
    }
    setProgress(true)
    dispatch(
      setValues({
        onDemandSearchInProgress: true,
      }),
    )

    const params = {
      space_id: location.id,
      isDaily: isPerDayOnDemand,
      team_size: count,
      start_date: format(startDate || customStartDate, "yyyy-MM-dd"),
      end_date: format(endDate || startDate || customStartDate, "yyyy-MM-dd"),
      start_time: startTime,
      end_time: endTime,
      option_cap: 1,
    }

    await request("filter-options", params)
      .then(res => {
        console.log(res?.options)
        if (res.options && res.options?.length) {
          // available
          dispatch(
            setValues({
              noOnDemandOptionsAvailable: false,
              selectedOnDemandOption: res.options[0],
            }),
          )
        } else {
          // no options available
          dispatch(
            setValues({
              noOnDemandOptionsAvailable: true,
            }),
          )
        }
      })
      .catch(e => {
        notifyError(
          `Failed to get the available on-demand offices in ${
            typeof window !== "undefined"
              ? window.location.href
              : process.env.GATSBY_CLIENT_NAME
          }`,
        )
        console.log(e)
      })
    setProgress(false)
  }

  return (
    <>
      <h4 className={"text-start my-0 d-none d-xl-block"}>
        {selectedOnDemandOption && selectedOnDemandOption.id
          ? `$${pricePerItem}/ ${itemUnit[0]}`
          : "Add seat count for prices"}
      </h4>

      <ul className={styles.input_list}>
        <li onClick={e => e.stopPropagation()}>
          <button
            className={styles.date_picker}
            onClick={() => {
              setIsDatepickerVisible(true)
              setTsOpen(false)
            }}
          >
            <span>Start &amp; end Date</span>
            <span>{dateLabel}</span>
          </button>
          {isDatepickerVisible && (
            <div
              className={styles.date_picker_modal_wrapper}
              onClick={e => e.stopPropagation()}
            >
              <Datepicker close={() => setIsDatepickerVisible(false)} />
            </div>
          )}
        </li>
        <li>
          <button
            className={styles.date_picker}
            onClick={e => {
              e.stopPropagation()
              setTsOpen(true)
              setIsDatepickerVisible(false)
            }}
          >
            <span>
              {count === 0
                ? "Add seat count"
                : `${count} ${count === 1 ? "person" : "people"}`}
            </span>
          </button>
          {tsOpen && (
            <div
              className={styles.date_picker_modal_wrapper}
              onClick={e => e.stopPropagation()}
            >
              <h4 className={"mb-3"}>Team size</h4>
              <TeamSizeUl
                teamSize={count}
                dismissibleForMobiles={() => setTsOpen(false)}
                setTeamSize={value => {
                  setTsOpen(false)
                  dispatch(setValues({ teamSize: value }))
                }}
              />
            </div>
          )}
        </li>
      </ul>

      {selectedOnDemandOption.id ? (
        <>
          <PriceSummaryTable
            rows={[
              {
                label: `$${pricePerItem} x ${onDemandDaysHoursCount} ${
                  itemUnit[onDemandDaysHoursCount > 1 ? 1 : 0]
                }`,
                value: `${currencyFormat(subtotal)}`,
              },
              {
                hidden: !TAX_LABEL || !TAX_PERCENTAGE,
                label: `${TAX_LABEL} (${TAX_PERCENTAGE * 100}%)`,
                value: `${currencyFormat(hst)}`,
              },
              {
                label: `Total`,
                value: `${currencyFormat(total)}`,
              },
            ]}
          />

          <ConditionsList list={["No charges until your booking begins"]} />
          <SubmitButton
            onClick={() => {
              dispatch(
                setValues({
                  checkoutPage: true,
                  total,
                  subtotal,
                  teamSize: count,
                  hst,
                  pricePerItem,
                  startTime,
                  endTime,
                }),
              )
            }}
            progress={false}
            disabled={false}
            label={"Check-Out"}
            className={"mt-4"}
            fullWidth
          />
        </>
      ) : (
        <>
          {!progress &&
            noOnDemandOptionsAvailable &&
            !selectedOnDemandOption.id && (
              <p className={"mt-3 mb-0"}>
                <small>
                  No results. Try adjusting your team size or your dates.
                </small>
              </p>
            )}
          <SubmitButton
            onClick={search}
            progress={progress}
            disabled={!count}
            label={"Check Availability"}
            className={"mt-4"}
            fullWidth
          />
        </>
      )}
    </>
  )
}

export default Index
