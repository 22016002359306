import React, { useEffect, useState } from "react"
import { Marker, ResultsMap } from "@components/ResultsMap"
import * as styles from "./index.module.less"
import { useSelector } from "react-redux"

const Location = () => {
  const [center, setCenter] = useState({
    lat: 43.659856,
    lng: -79.3799863,
  })

  const location = useSelector(state => state.profile.location)

  useEffect(() => {
    if (
      location.office_address &&
      location.office_address.lat &&
      location.office_address.lng
    ) {
      setCenter({
        lat: location.office_address.lat,
        lng: location.office_address.lng,
      })
    }
  }, [location])

  if (location.length === 0 || !location.office_address) {
    return null
  }
  return (
    <div className="container">
      <div className={styles.space_profile_location}>
        <div className="row">
          <div className="col-sm-12">
            <h2>Location</h2>
            {location.office_address.address && (
              <a
                href={`https://maps.google.com/?q=${location.office_address.address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {location.office_address.address}
              </a>
            )}

            <div className={styles.space_profile_map}>
              <ResultsMap center={center} defaultZoom={13}>
                <Marker
                  lat={location.office_address.lat}
                  lng={location.office_address.lng}
                  url={`https://maps.google.com/?q=${location.office_address.address}`}
                  className={`static-map ${process.env.GATSBY_CLIENT_SLUG}`}
                  text={""}
                />
              </ResultsMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Location
