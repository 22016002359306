import React, { useEffect, useState } from "react"
import * as styles from "./index.module.less"
import { resTypes } from "@config"
import { navigate } from "gatsby"
import qs from "query-string"
import { useDispatch, useSelector } from "react-redux"
import { setValues } from "../../../../stores/ProfileStore/actions"

const InlineBookingLayout = ({ children }) => {
  const dispatch = useDispatch()
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)

  const params =
    typeof window !== "undefined" ? qs.parse(window.location.search) : {}

  const [scrollY, setSy] = useState(0)

  useEffect(() => {
    if (typeof window === "undefined") return
    window.scroll(0, scrollY)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const changeType = type => {
    if (typeof window === "undefined") return
    const queryParams = qs.parse(window.location.search)
    delete queryParams.express_checkout
    delete queryParams.res_type
    queryParams.res_type = type
    const itemUnitForOnDemand = isPerDayOnDemand
      ? ["day", "days"]
      : ["hour", "hours"]
    dispatch(
      setValues({
        queryParams: queryParams,
        reservation_type: type,
        reservationType: resTypes.find(({ slug }) => slug === type),
        itemUnit:
          type === "day-passes" ? ["pass", "passes"] : itemUnitForOnDemand,
      })
    )
    if (type === "on-demand-offices" && isPerDayOnDemand) {
      dispatch(setValues({ startTime: "" }))
    }
    navigate(`${window.location.pathname}?${qs.stringify(queryParams)}`)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => setSy(window.scrollY))
    return () => {
      window.addEventListener("scroll", () => setSy(window.scrollY))
    }
  }, [])

  return (
    <div className={styles.wrapper} id={"inline_reservation"}>
      {resTypes.length > 1 && (
        <ul>
          {resTypes.map(({ name, slug }) => (
            <li key={slug}>
              <button
                onClick={() => {
                  changeType(slug)
                }}
                className={
                  params.res_type === slug ? styles.active_res_type : ""
                }
              >
                {name}
              </button>
            </li>
          ))}
        </ul>
      )}
      <div>{children}</div>
    </div>
  )
}

export default InlineBookingLayout
