import qs from "query-string"

const getSelectedOffices = space => {
  if (typeof window === "undefined") {
    return []
  }
  const spaceSummary = qs.parse(window.location.search)
  if (!spaceSummary.selected_office_ids) {
    return []
  }

  const selected_office_ids = spaceSummary.selected_office_ids.split(",")
  let selected_offices = []

  selected_office_ids.forEach(id => {
    const item = space.offices && space.offices.find(item => item.id === id)
    if (item) {
      selected_offices.push(item)
    }
  })

  let final_list = []
  selected_offices.forEach(office => {
    const existing = final_list.find(item => item.floor_id === office.floor_id)
    if (!existing) {
      final_list.push({
        floor_id: office.floor_id,
        map_background_url: office.map_background_url,
        map_svg_url: office.map_svg_url,
        items: [],
      })
    }
  })

  final_list.forEach(office => {
    selected_offices.forEach(el => {
      if (el.floor_id === office.floor_id) {
        office.items.push(el)
      }
    })
  })

  return final_list.sort(function (a, b) {
    return a.price_per_day - b.price_per_day
  })
}

export default getSelectedOffices
