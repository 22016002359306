import React from "react"
import * as styles from "./index.module.less"
import Counter from "@components/Counter"
import { useDispatch, useSelector } from "react-redux"
import { setValues } from "../../stores/ProfileStore/actions"
import { format } from "date-fns"

const Index = ({ rows = [], noBorders = false, dayPassesCounter = false }) => {
  const reservation_type = useSelector(state => state.profile.reservation_type)
  return (
    <>
      {dayPassesCounter && <CounterWrapper />}
      <ul className={`${styles.list} ${noBorders ? styles.no_borders : ""}`}>
        {rows.map(
          ({ label, value, hidden = false, datesForDayPasses = [] }, idx) => {
            if (hidden) return null
            return (
              <li key={idx}>
                <span>{label}</span>
                <span>{value}</span>
                {reservation_type === "day-passes" &&
                  datesForDayPasses &&
                  datesForDayPasses.length > 1 && (
                    <ul>
                      {datesForDayPasses.map((date, idx) => (
                        <li key={idx}>{format(date, "MMMM d, yyyy")}</li>
                      ))}
                    </ul>
                  )}
              </li>
            )
          },
        )}
      </ul>
    </>
  )
}

export default Index

const CounterWrapper = () => {
  const dispatch = useDispatch()
  const teamSize = useSelector(state => state.profile.teamSize)
  const reservation_type = useSelector(state => state.profile.reservation_type)

  const location = useSelector(state => state.profile.location)

  if (reservation_type !== "day-passes") return null

  return (
    <div className={styles.counter_wrapper}>
      Number of passes
      <Counter
        count={teamSize}
        setCount={teamSize => dispatch(setValues({ teamSize }))}
        max={location?.day_passes_per_day || 10}
      />
    </div>
  )
}
