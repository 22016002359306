import { isBefore, isSameDay } from "date-fns"
import alreadyReserved from "./alreadyReserved"

const filterSpacesForSingleSpaces = (
  data,
  singleSpaceTeamSize,
  startDate,
  endDate
) => {
  if (!singleSpaceTeamSize || !endDate) return

  const teamSizes = singleSpaceTeamSize.split("-")

  const min_ts = Number(teamSizes[0])
  const buffer = min_ts < 7 ? 2 : 3
  const max_ts = teamSizes[1]
    ? Number(teamSizes[1])
    : Number(teamSizes[0]) + buffer

  return data.offices && data.offices.length > 0
    ? data.offices
        .filter(office => {
          // If !space_available && !space_available_from

          if (!office.price_per_day || office.price_per_day === 0) return false
          if (!office.space_available && !office.space_available_from)
            return false
          // Space is available at some point.
          // Matching: max_capacity & available date && no active reservations within the date range
          const capacity_match =
            office.max_capacity >= min_ts && office.max_capacity <= max_ts
          const is_available =
            office.space_available ||
            (!office.space_available &&
              office.space_available_from &&
              isBefore(new Date(office.space_available_from), startDate)) ||
            isSameDay(new Date(office.space_available_from), startDate)
          const already_reserved =
            data.reservations && data.reservations.length > 0
              ? data.reservations.find(res => {
                  if (res.id !== office.id) return false
                  return alreadyReserved(res.res, startDate, endDate)
                })
              : false
          return capacity_match && is_available && !already_reserved
        })
        .sort(function (a, b) {
          return a.price_per_day - b.price_per_day
        })
        .slice(0, 2)
    : []
}

export default filterSpacesForSingleSpaces
