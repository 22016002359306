import { isSameDay } from "date-fns"

const addNewDateForDayPasses = (existingDates, newDate) => {
  if (!existingDates || !newDate) return []

  let newDates = []

  if (existingDates.find(d => isSameDay(newDate, d))) {
    // date already exists
    newDates = existingDates.filter(d => !isSameDay(d, newDate))
  } else {
    // new date
    newDates = [...existingDates, newDate]
  }

  newDates.sort((a, b) => a - b)

  return newDates
}

export default addNewDateForDayPasses
