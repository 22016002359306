import React, { useState } from "react"
import InputMask from "react-input-mask"
import isMobilePhone from "validator/lib/isMobilePhone"

const PhoneNumber = ({
  setPhone,
  value,
  clearErrors = () => null,
  placeholder = "Phone number",
}) => {
  const [mask, setMask] = useState("999 999 9999")
  return (
    <InputMask
      mask={mask}
      type="text"
      maskChar={null}
      placeholder={placeholder}
      value={value}
      className={"form-control"}
      onChange={e => {
        setPhone(e.target.value)
        clearErrors()
        if (value && value.length >= 2) {
          setMask("(999) 999 9999")
        } else {
          setMask("999 999 9999")
        }
      }}
    />
  )
}

export default PhoneNumber

export const isValidPhone = (phone, required = false) => {
  if (!required && !phone) {
    return true
  }
  return phone && phone.length > 7 && isMobilePhone(phone)
}
