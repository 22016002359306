import React from "react"
import * as styles from "./index.module.less"
import getResizedImageUrl from "@utils/getResizedImageUrl"
import { useSelector } from "react-redux"

const Title = () => {
  const location = useSelector(state => state.profile.location)

  return (
    <>
      <h1 className={styles.title}>Reserve now</h1>
      <div className={styles.reservation_intro}>
        {location.hero_image_url && (
          <img
            src={getResizedImageUrl(location.hero_image_url, 360)}
            alt={location.space_name}
          />
        )}
        <div>
          <h4>{location.space_name}</h4>
          {location.office_address && (
            <p>{location.office_address.short_address}</p>
          )}
        </div>
      </div>
    </>
  )
}

export default Title
