// extracted by mini-css-extract-plugin
export var active_res_type = "index-module--active_res_type--YMRt-";
export var bodyFont = "index-module--body-font--CzcID";
export var body_wrapper = "index-module--body_wrapper--o0hmb";
export var borderRadius = "index-module--border-radius--RSeKs";
export var clearButtonStyles = "index-module--clear-button-styles--HO3vz";
export var date_picker = "index-module--date_picker--gdP9z";
export var datepicker_heading = "index-module--datepicker_heading--4nKZ3";
export var header = "index-module--header--1KlwI";
export var message = "index-module--message--3Uv2Q";
export var mobile_booking = "index-module--mobile_booking---xRcc";
export var next = "index-module--next--sf36w";
export var options_heading = "index-module--options_heading--uzTfK";
export var res_type = "index-module--res_type--orI0u";
export var time_picker_wrapper = "index-module--time_picker_wrapper--ycbzB";
export var titleFont = "index-module--title-font--UnJnU";
export var type_wrapper = "index-module--type_wrapper--R3S8Z";