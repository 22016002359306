import React from "react"
import * as styles from "./index.module.less"
import { useDispatch, useSelector } from "react-redux"
import { setValues } from "../../../stores/ProfileStore/actions"

const StickyBar = () => {
  const dispatch = useDispatch()
  const location = useSelector(state => state.profile.location)
  const reservation_type = useSelector(state => state.profile.reservation_type)
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)
  const selectedOnDemandOption = useSelector(
    state => state.profile.selectedOnDemandOption
  )

  let onDemandPrice = 0

  if (selectedOnDemandOption && selectedOnDemandOption.id) {
    onDemandPrice = isPerDayOnDemand
      ? selectedOnDemandOption.price_per_day
      : selectedOnDemandOption.price_per_hour
  }

  return (
    <div className={styles.sticky_bar}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={`${styles.left}`}>
            {reservation_type === "on-demand-offices" ? (
              <>
                {onDemandPrice > 0 && (
                  <h4 className={""}>
                    ${onDemandPrice}/{isPerDayOnDemand ? "day" : "hour"}
                  </h4>
                )}
              </>
            ) : (
              <>
                <h4 className={""}>
                  ${location.price_per_day_pass}
                  /pass
                </h4>
              </>
            )}
          </div>
          <div className={`${styles.right}`}>
            <button
              className="button primary"
              onClick={() =>
                dispatch(
                  setValues({
                    mobileOptionPicker: true,
                  })
                )
              }
            >
              {reservation_type === "on-demand-offices" ? (
                <>
                  {onDemandPrice === 0 ? "Check availability" : "Reserve Now"}
                </>
              ) : (
                ""
              )}
              {reservation_type === "day-passes" ? "Check-Out" : ""}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StickyBar
