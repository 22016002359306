import React, { useEffect, useState } from "react"
import * as styles from "./index.module.less"
import { format, isSameDay } from "date-fns"
import Floor from "../../../FloorPlans/Floor"
import { useSelector } from "react-redux"

const Pdf = ({ reservationIdForThePdf = "" }) => {
  const openingTime = useSelector(state => state.profile.openingTime)
  const closingTime = useSelector(state => state.profile.closingTime)
  const user = useSelector(state => state.profile.user)
  const location = useSelector(state => state.profile.location)
  const startDate = useSelector(state => state.profile.startDate)
  const startTime = useSelector(state => state.profile.startTime)
  const endDate = useSelector(state => state.profile.endDate)
  const endTime = useSelector(state => state.profile.endTime)
  const teamSize = useSelector(state => state.profile.teamSize)
  const reservation_type = useSelector(state => state.profile.reservation_type)
  const reservationType = useSelector(state => state.profile.reservationType)
  const itemUnit = useSelector(state => state.profile.itemUnit)
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)
  const selectedOnDemandOption = useSelector(
    state => state.profile.selectedOnDemandOption
  )
  const tentativeArrivalTime = useSelector(
    state => state.profile.tentativeArrivalTime
  )
  const datesForDayPasses = useSelector(
    state => state.profile.datesForDayPasses
  )

  const setDisplayDate = () => {
    if (reservation_type === "day-passes") {
      if (datesForDayPasses && datesForDayPasses.length === 1) {
        return format(datesForDayPasses[0], "MMMM d yyyy")
      } else {
        return datesForDayPasses
          .map(date => format(date, "MMMM d yyyy"))
          .join(", ")
      }
    } else if (startDate && endDate) {
      if (!isSameDay(startDate, endDate)) {
        return `${format(startDate, "MMMM d yyyy")} - ${format(
          endDate,
          "MMMM d yyyy"
        )}`
      } else if (startDate) {
        return format(startDate, "MMMM d yyyy")
      }
    }
    return format(new Date(), "MMMM d yyyy")
  }

  const [displayDate, setDd] = useState(setDisplayDate())
  const [displayStartTime, setDst] = useState(openingTime || "09:00 am")
  const [displayEndTime, setDet] = useState(closingTime || "05:00 pm")

  useEffect(() => {
    if (reservation_type === "on-demand-offices") {
      if (!isPerDayOnDemand) {
        if (startTime) setDst(format(startTime, "hh:mm a"))
        if (endTime) setDet(format(endTime, "hh:mm a"))
      } else {
        if (startTime) setDst(format(startTime, "hh:mm a"))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation_type, isPerDayOnDemand, startTime])

  useEffect(() => {
    setDd(setDisplayDate())
  }, [startDate, reservation_type, endDate, datesForDayPasses])

  return (
    <div
      className={process.env.NODE_ENV === "production" ? "d-none" : "d-none"}
    >
      <div className={styles.pdf_wrapper}>
        <div id="pdf_container">
          <div className="">
            <div className={"row"}>
              <div className="col-sm-12">
                <img
                  src={
                    require(`../../../../../images/clients/${
                      process.env.GATSBY_CLIENT_SLUG || "default"
                    }/logo.svg`).default
                  }
                  alt={location.space_name}
                  height={80}
                />
                <h3 className={styles.reservation_pdf_title}>
                  Booking confirmation
                </h3>
                {reservationIdForThePdf && (
                  <p className={"mb-3"}>
                    Reservation ID: {reservationIdForThePdf}
                  </p>
                )}
              </div>
              <div className="col-sm-12">
                <hr />
              </div>
              <div className="col-6">
                <h4>Space details</h4>
                <ul className={styles.reservation_pdf_summary}>
                  <li>{location.space_name}</li>
                  {location.office_address &&
                    location.office_address.address && (
                      <li>{location.office_address.address}</li>
                    )}
                  <li>{reservationType.name}</li>
                  {reservation_type === "day-passes" ? (
                    <li>{`${teamSize} ${itemUnit[teamSize > 1 ? 1 : 0]}`}</li>
                  ) : (
                    <li>{`${teamSize} ${
                      teamSize > 1 ? "people" : "person"
                    }`}</li>
                  )}
                </ul>
              </div>
              <div className="col-6">
                <h4>Guest details</h4>
                <ul className={styles.reservation_pdf_summary}>
                  <li>{`Full Name: ${user.name}`}</li>
                  <li>{`Email: ${user.email}`}</li>
                  <li>{`Phone number: ${user.phone}`}</li>
                  <li>{`Date: ${displayDate}`}</li>
                  <li>{`Arrival time: ${
                    tentativeArrivalTime || displayStartTime
                  }`}</li>
                  <li>{`Checkout Time: ${displayEndTime}`}</li>
                </ul>
              </div>
              {location.on_demand_instructions && (
                <div className="col-sm-12">
                  <hr />
                  <h4>Arrival instructions</h4>
                  <ul className={styles.reservation_pdf_summary}>
                    {location.on_demand_instructions
                      .split("\n")
                      .map((text, idx) => (
                        <li key={idx} className={"mb-1"}>
                          {text}
                        </li>
                      ))}
                  </ul>
                </div>
              )}

              {reservation_type === "on-demand-offices" ? (
                <div className="col-sm-12 mt-5 pb-5">
                  <Floor />
                </div>
              ) : (
                <>
                  <div className={"col-sm-12 pb-5 mt-5"} />
                  <div className={"col-sm-12 pb-5 mt-5"} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pdf
