import React from "react"

const Input = ({
  type = "text",
  placeholder = "",
  value = "",
  onChange = () => null,
  children,
  className = "",
  ...props
}) => {
  if (type === "select") {
    return (
      <select
        value={value}
        onChange={onChange}
        className={`form-control client-font ${className}`}
      >
        {children}
      </select>
    )
  }
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`form-control ${className}`}
      {...props}
    />
  )
}

export default Input
