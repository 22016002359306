import React, { useEffect, useState } from "react"
import { differenceInHours } from "date-fns"
import Datepicker from "react-datepicker"
import { hasHourlyOnDemand } from "@config"
import countWorkingDays from "../../../../utils/countWorkingDays"
import OnDemandTypeSelector from "@components/OnBoarding/OnDemandTypeSelector"
import HourlyTimePicker from "../../../OnBoarding/HourlyTimePicker"
import * as styles from "./index.module.less"
import { useDispatch, useSelector } from "react-redux"
import { setValues } from "../../../../stores/ProfileStore/actions"
import maxDateForCalendar from "../../../../utils/maxDateForCalendar"

const OnDemand = ({ close = () => null }) => {
  const dispatch = useDispatch()
  const startDate = useSelector(state => state.profile.startDate)
  const minDate = useSelector(state => state.profile.minDate)
  const endDate = useSelector(state => state.profile.endDate)
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)
  const startTime = useSelector(state => state.profile.startTime)
  const endTime = useSelector(state => state.profile.endTime)
  const location = useSelector(state => state.profile.location)
  const holidays = useSelector(state => state.profile.holidays)

  const setStartTime = value => dispatch(setValues({ startTime: value }))
  const setEndTime = value => dispatch(setValues({ endTime: value }))

  const [type, setType] = useState(isPerDayOnDemand ? "Daily" : "Hourly")

  const setDates = dates => {
    if (typeof dates === "object" && dates.length === 2) {
      const [start, end] = dates
      dispatch(
        setValues({
          startDate: start,
          endDate: end,
        })
      )
      if (isPerDayOnDemand && start && end) {
        close()
        dispatch(
          setValues({
            onDemandDaysHoursCount: countWorkingDays(start, end, []),
          })
        )
      }
    } else {
      setStartTime("")
      setEndTime("")
      dispatch(
        setValues({
          startDate: dates,
          endDate: null,
        })
      )
    }
    dispatch(
      setValues({
        noOnDemandOptionsAvailable: false,
        selectedOnDemandOption: {},
      })
    )
  }

  useEffect(() => {
    if (isPerDayOnDemand || !startTime || !endTime) return
    dispatch(
      setValues({
        onDemandDaysHoursCount: differenceInHours(endTime, startTime),
      })
    ) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime])

  const [message, setMessage] = useState("")

  useEffect(() => {
    // setting the message
    if (isPerDayOnDemand) {
      if (!endDate) {
        setMessage("Please select an end date")
        return
      } else if (!startDate || (startDate && endDate)) {
        setMessage("Please select a start date")
        return
      }
    } else if (!isPerDayOnDemand) {
      if (!startTime) {
        setMessage("Please select start time")
        return
      } else if (!endTime) {
        setMessage("Please select end time")
        return
      }
    }
    setMessage("")
  }, [startDate, endDate, isPerDayOnDemand, startTime, endTime])

  return (
    <>
      {hasHourlyOnDemand && (
        <OnDemandTypeSelector
          type={type}
          setType={t => {
            setType(t)
            dispatch(
              setValues({ isPerDayOnDemand: t === "Daily", endDate: startDate })
            )
          }}
        />
      )}
      <Datepicker
        inline
        selectsRange={isPerDayOnDemand}
        minDate={minDate || new Date()}
        maxDate={maxDateForCalendar(process.env.GATSBY_CLIENT_UUID || "")}
        selected={startDate}
        onChange={setDates}
        startDate={startDate}
        endDate={endDate}
        excludeDates={holidays[location.city] || []}
      />
      {message && <p className={styles.message}>{message}</p>}

      {!isPerDayOnDemand && startDate && (
        <HourlyTimePicker
          alwaysBottom
          startDate={startDate}
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={value => {
            setEndTime(value)
            close()
          }}
        />
      )}
    </>
  )
}

export default OnDemand
