import React from "react"
import Svg from "react-inlinesvg"
import PropTypes from "prop-types"
import useOptimizedImages from "@utils/useOptimizedImages"
import "./index.less"
import { useSelector } from "react-redux"

const Floor = () => {
  const onDemandDaysHoursCount = useSelector(
    state => state.profile.onDemandDaysHoursCount
  )
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)
  const selectedOnDemandOption = useSelector(
    state => state.profile.selectedOnDemandOption
  )

  const unitPrice = isPerDayOnDemand
    ? selectedOnDemandOption.price_per_day
    : selectedOnDemandOption.price_per_hour

  const customImage = useOptimizedImages(
    selectedOnDemandOption.plan_bg,
    1200,
    1200,
    1200,
    1200
  )
  const floorBG =
    typeof window !== "undefined" ? customImage : selectedOnDemandOption.plan_bg

  return (
    <div className="space-layout-wrapper">
      <div className={"row "}>
        <div className={"col-7 mb-5"}>
          <div className="space-layout">
            <img src={floorBG} alt="" />
            <Svg
              src={`${
                process.env.GATSBY_SERVER_URL
              }/upload/getSvg?url=${encodeURIComponent(
                selectedOnDemandOption.plan_svg
              )}`}
              onLoad={() => {
                const indexedSvgs = {}
                const allSvgElements =
                  document.querySelectorAll("svg path, svg rect")

                for (let index = 0; index < allSvgElements.length; index++) {
                  const element = allSvgElements[index]
                  const svgId = getIdentifierFromSvg(element)
                  indexedSvgs[svgId] = element
                  element.setAttribute("fill", "transparent")
                  element.setAttribute("stroke", "transparent")

                  if (
                    selectedOnDemandOption &&
                    selectedOnDemandOption.svg_identifier === svgId
                  ) {
                    element.setAttribute("fill-opacity", 1)
                    element.setAttribute("fill", "#478261")
                    element.setAttribute("class", `active`)
                  }
                }
              }}
            />
          </div>
        </div>
        <div className={"col-5"}>
          <ul className={`option-list`}>
            <li className={`for-pdf`}>
              <div>
                <span className="box" />
              </div>
              <div className={`option-desc-container for-pdf`}>
                <span className={"option-text mb-0"}>
                  <small>Selected Option</small>
                </span>

                <>
                  <small>
                    <strong data-testid={"option-unit-price"}>
                      {`$${Number(unitPrice || 0).toLocaleString("en-US")}/${
                        isPerDayOnDemand ? "day" : "hour"
                      }`}
                    </strong>
                  </small>
                  <br />
                  <span data-testid={"option-price"}>
                    {`$${Number(
                      unitPrice * onDemandDaysHoursCount
                    ).toLocaleString("en-US")} for ${onDemandDaysHoursCount} ${
                      isPerDayOnDemand ? "day" : "hour"
                    }${onDemandDaysHoursCount === 1 ? "" : "s"}`}
                  </span>
                </>
                {selectedOnDemandOption.max_capacity && (
                  <span data-testid={"option-max-capacity"}>
                    {selectedOnDemandOption.max_capacity === 1
                      ? "1 person"
                      : `Up to ${selectedOnDemandOption.max_capacity} people`}
                  </span>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

Floor.propTypes = {
  svg: PropTypes.string,
  bg: PropTypes.string,
  spaces: PropTypes.array,
  bundle: PropTypes.bool,
  handleModal: PropTypes.func,
  optionStart: PropTypes.number,
}

export default Floor

const getIdentifierFromSvg = element => {
  let whitelistedAttributes = []
  let identifier = []
  switch (element.tagName) {
    case "rect":
      whitelistedAttributes = ["width", "x", "y", "height"]
      break
    case "path":
      whitelistedAttributes = ["d"]
      break
    default:
      break
  }

  for (let i = 0; i < element.attributes.length; i++) {
    for (let j = 0; j < whitelistedAttributes.length; j++) {
      if (element.attributes[i].nodeName === whitelistedAttributes[j]) {
        identifier.push(
          whitelistedAttributes[j] + ":" + element.attributes[i].nodeValue
        )
      }
    }
  }

  return identifier.join("|")
}
