import React from "react"
import DayPasses from "./DayPasses"
import OnDemand from "./OnDemand"

import Layout from "./Layout"
import { useSelector } from "react-redux"

const InlineBooking = () => {
  const reservation_type = useSelector(state => state.profile.reservation_type)

  return (
    <Layout>
      {reservation_type === "day-passes" && <DayPasses />}
      {reservation_type === "on-demand-offices" && <OnDemand />}
    </Layout>
  )
}

export default InlineBooking
