import request from "@services/request"
import notifyError from "../../../../../utils/notifyError"

const uploadPdf = async (reservationId = null) => {
  const element = document.getElementById("pdf_container")
  if (typeof window === "undefined" || !element || !reservationId) {
    return {}
  }

  const html2pdf = require("html2pdf.js")

  const opt = {
    margin: [10, 10, 10, 10],
    filename: `reservation.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {
      useCORS: true,
      scrollX: 0,
      scrollY: 0,
      windowWidth: window.innerWidth,
    },
    jsPDF: { unit: "mm", format: "legal", orientation: "portrait" },
  }

  // This is a base64 encoded string
  let generatedPDF = null
  try {
    generatedPDF = await html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .output("datauristring")
  } catch (e) {
    notifyError(
      `Error creating the PDF in ${
        typeof window !== "undefined"
          ? window.location.href
          : process.env.GATSBY_CLIENT_NAME
      }`
    )
    console.log(e)
  }

  const response = await request("upload/getSignedUrl", {
    spDataId: "placeholder-to-bypass-save-to-db",
    existingImage: false,
    category: "Placeholder Category",
    key: "reservation",
    filename: `${reservationId}.pdf`,
  })

  if (!response.Url) {
    notifyError(
      `Failed to get the signed URL for the PDF upload in ${
        typeof window !== "undefined"
          ? window.location.href
          : process.env.GATSBY_CLIENT_NAME
      }`
    )
    return {}
  }

  // this is to access the URL for the success message
  let binary = atob(generatedPDF.split(",")[1])
  let array = []
  // for each letter in the encrypted version, get binary value and push it to array
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  // use the array to construct a binary object (blob)
  let blobData = new Blob([new Uint8Array(array)], {
    type: "application/pdf",
  })
  try {
    await fetch(response.uploadURL, {
      method: "PUT",
      body: blobData,
      mode: "cors",
    })
  } catch (e) {
    console.log("uploading pdf failed")
    notifyError(
      `Failed to upload the PDF to S3 in ${
        typeof window !== "undefined"
          ? window.location.href
          : process.env.GATSBY_CLIENT_NAME
      }`
    )
    return {
      pdfUrl: null,
      base64PDF: generatedPDF,
    }
  }
  return {
    pdfUrl: response.Url,
    base64PDF: generatedPDF,
  }
}

export default uploadPdf
