import React, { useEffect, useState } from "react"
import Counter from "@components/Counter"
import * as styles from "./index.module.less"
import PriceSummaryTable from "@components/PriceSummaryTable"
import currencyFormat from "@utils/currencyFormat"
import { setValues } from "../../../../stores/ProfileStore/actions"
import Datepicker from "react-datepicker"
import { format, isWeekend } from "date-fns"
import nextMonday from "@utils/nextMonday"
import { useDispatch, useSelector } from "react-redux"
import { today } from "../../../../utils/config"
import addNewDateForDayPasses from "../../../../utils/addNewDateForDayPasses"
import maxDateForCalendar from "../../../../utils/maxDateForCalendar"

const Index = () => {
  const [isDatepickerVisible, setIsDatepickerVisible] = useState(false)

  useEffect(() => {
    window.addEventListener("click", () => setIsDatepickerVisible(false))
    return () => {
      window.removeEventListener("click", () => setIsDatepickerVisible(false))
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useDispatch()
  const location = useSelector(state => state.profile.location)
  const startDate = useSelector(state => state.profile.startDate)
  const datesForDayPasses = useSelector(
    state => state.profile.datesForDayPasses,
  )
  const numberOfDayPasses = useSelector(
    state => state.profile.numberOfDayPasses,
  )
  const queryParams = useSelector(state => state.profile.queryParams)
  const itemUnit = useSelector(state => state.profile.itemUnit)
  const couponDiscountPercentage = useSelector(
    state => state.couponDiscountPercentage,
  )
  const couponDiscount = useSelector(state => state.couponDiscount)
  const minDate = useSelector(state => state.profile.minDate)
  const TAX_LABEL = useSelector(state => state.profile.TAX_LABEL)
  const TAX_PERCENTAGE = useSelector(state => state.profile.TAX_PERCENTAGE)
  const holidays = useSelector(state => state.profile.holidays)

  const setDate = date => {
    if (!date) return

    dispatch(
      setValues({
        datesForDayPasses: addNewDateForDayPasses(datesForDayPasses, date),
      }),
    )
  }

  useEffect(() => {
    if (!startDate) {
      dispatch(
        setValues({
          itemUnit: ["pass", "passes"],
          startDate: isWeekend(new Date()) ? nextMonday() : today,
          isPerDayOnDemand: false,
        }),
      )
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (queryParams.express_checkout) {
      const numberOfDayPasses = queryParams.team_size
        ? Number(queryParams.team_size)
        : 1
      const pricePerItem = location.price_per_day_pass
      const numberOfDays = datesForDayPasses.length || 1
      const hst =
        pricePerItem * numberOfDayPasses * numberOfDays * TAX_PERCENTAGE
      const subtotal = pricePerItem * numberOfDayPasses * numberOfDays
      const totalBeforeTaxes = (1 - couponDiscountPercentage) * subtotal
      const total = totalBeforeTaxes * (1 + TAX_PERCENTAGE)

      dispatch(
        setValues({
          itemUnit: ["pass", "passes"],
          checkoutPage: !!queryParams.express_checkout,
          numberOfDayPasses,
          teamSize: numberOfDayPasses,
          pricePerItem,
          hst,
          subtotal,
          total,
        }),
      )
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const [count, setCount] = useState(
    Number(queryParams.team_size) || numberOfDayPasses,
  )

  const [subtotal, setSubtotal] = useState(location.price_per_day_pass * count)
  const [hst, setHst] = useState(
    location.price_per_day_pass * count * TAX_PERCENTAGE,
  )
  const [total, setTotal] = useState(
    location.price_per_day_pass * count * (1 + TAX_PERCENTAGE),
  )

  useEffect(() => {
    const numberOfDays = datesForDayPasses.length || 1
    setSubtotal(location.price_per_day_pass * numberOfDays * count)
    setHst(location.price_per_day_pass * numberOfDays * count * TAX_PERCENTAGE)
    setTotal(
      location.price_per_day_pass * numberOfDays * count * (1 + TAX_PERCENTAGE),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, datesForDayPasses, couponDiscountPercentage])

  if (!startDate) return null

  return (
    <>
      <h4 className={"text-start my-0"}>${location.price_per_day_pass}/pass</h4>

      <ul className={styles.input_list}>
        <li onClick={e => e.stopPropagation()}>
          <button
            className={styles.date_picker}
            onClick={() =>
              setIsDatepickerVisible(
                typeof window !== "undefined" && window.innerWidth >= 1200,
              )
            }
          >
            <span>Drop-in Date</span>
            <span>
              {datesForDayPasses && datesForDayPasses.length > 0
                ? datesForDayPasses.length === 1
                  ? format(datesForDayPasses[0], "MMMM d")
                  : "Multiple dates"
                : "Please select"}
            </span>
          </button>
          {isDatepickerVisible && (
            <div
              className={styles.date_picker_modal_wrapper}
              onClick={e => e.stopPropagation()}
            >
              <span className="datepicker-custom-help-text">
                Multiple days can be selected
              </span>
              <Datepicker
                inline
                minDate={minDate || new Date()}
                maxDate={maxDateForCalendar(
                  process.env.GATSBY_CLIENT_UUID || "",
                )}
                onChange={setDate}
                startDate={startDate}
                // endDate={endDate}
                excludeDates={holidays[location.city] || []}
                highlightDates={datesForDayPasses}
              />
              <button
                className={`button ${styles.select_dates_button}`}
                onClick={() => setIsDatepickerVisible(false)}
              >
                Select Dates
              </button>
              <div className={styles.footer_actions}>
                <button
                  onClick={() => dispatch(setValues({ datesForDayPasses: [] }))}
                >
                  Clear All
                </button>
              </div>
            </div>
          )}
        </li>
        <li>
          <p className={"me-auto"}>Number of Passes</p>
          <Counter
            count={count}
            setCount={setCount}
            min={1}
            max={location?.day_passes_per_day || 10}
          />
        </li>
      </ul>

      <PriceSummaryTable
        rows={[
          {
            label: `$${location.price_per_day_pass} x ${count} ${
              itemUnit[count > 1 ? 1 : 0]
            } x ${datesForDayPasses.length || 1} day${
              datesForDayPasses.length > 1 ? "s" : ""
            }`,
            value: `${currencyFormat(subtotal)}`,
            datesForDayPasses,
          },
          {
            hidden: !TAX_PERCENTAGE || !TAX_LABEL,
            label: `${TAX_LABEL} (${TAX_PERCENTAGE * 100}%)`,
            value: `${currencyFormat(hst)}`,
          },
          {
            label: `Total`,
            value: `${currencyFormat(total)}`,
          },
        ]}
      />
      <button
        className={`button primary ${styles.check_out_button}`}
        disabled={
          !startDate ||
          count < 1 ||
          !datesForDayPasses ||
          datesForDayPasses.length === 0
        }
        onClick={() =>
          dispatch(
            setValues({
              checkoutPage: true,
              total,
              subtotal,
              numberOfDayPasses: count,
              teamSize: count,
              pricePerItem: location.price_per_day_pass,
              hst,
            }),
          )
        }
      >
        Check-Out
      </button>
    </>
  )
}

export default Index
