import qs from "query-string"

const getSelectedOfficesList = space => {
  if (typeof window === "undefined") {
    return []
  }
  const spaceSummary = qs.parse(window.location.search)
  if (!spaceSummary.selected_office_ids) {
    return []
  }

  const selected_office_ids = spaceSummary.selected_office_ids.split(",")
  let selected_offices = []

  selected_office_ids.forEach(id => {
    const item = space.offices && space.offices.find(item => item.id === id)
    if (item) {
      selected_offices.push(item)
    }
  })

  return selected_offices.sort(function (a, b) {
    return a.price_per_day - b.price_per_day
  })
}

export default getSelectedOfficesList
