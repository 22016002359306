const so = [
  "e4956e51-991c-40ce-aa73-4cbeacf5a711", // staging??
  "50b1ed74-a502-4833-b4c3-9453d3998c9d", // worksimply
  "2f33867f-cd37-4778-9daa-a07836ebd907", // workhaus
  "b2b1f09a-06d5-4491-a504-57f05a3e258c", // huddle
]

const couponEnabled = so.includes(process.env.GATSBY_CLIENT_UUID)

export default couponEnabled
