const getDailyPriceRange = options => {
  const min_price = options[0] ? options[0].price_per_day : ""
  const max_price = options[1] ? options[1].price_per_day : ""
  if (!min_price) return "-/day"

  let fpr = `$${min_price}`

  fpr += max_price && max_price > min_price ? ` - $${max_price}` : ""

  fpr += "/day"

  return fpr
}

export default getDailyPriceRange
