import React from "react"
import Hero from "./Hero"
import Gallery from "./Gallery"
import SpaceSummary from "./SpaceSummary"
import Amenities from "./Amenities"
import Location from "./Location"
import InlineBooking from "./InlineBooking"
import CheckOut from "./InlineBooking/CheckOut"
import StickyBar from "./StickyBar"
import * as styles from "./index.module.less"
import MobileBooking from "./MobileBooking"
import { useSelector } from "react-redux"

const SpaceProfile = () => {
  const mobileOptionPicker = useSelector(
    state => state.profile.mobileOptionPicker
  )
  const checkoutPage = useSelector(state => state.profile.checkoutPage)

  // if (reservation) {
  //   return <Reservation />
  // }

  const isDesktop = typeof window !== "undefined" && window.innerWidth >= 1200

  return (
    <>
      {checkoutPage ? (
        <CheckOut />
      ) : (
        <>
          <Hero />
          <Gallery />

          <div className="container">
            <div className="row">
              <div className={`col-xl-8 ${styles.space_profile_left}`}>
                <SpaceSummary />
                <Amenities />
              </div>
              {isDesktop && (
                <div
                  className={`col-lg-4 d-none d-xl-block ${styles.space_profile_right}`}
                >
                  <div>
                    <InlineBooking />
                  </div>
                </div>
              )}
            </div>
          </div>

          <Location />
          <StickyBar />
          {mobileOptionPicker && <MobileBooking />}
        </>
      )}
    </>
  )
}

export default SpaceProfile
