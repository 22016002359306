import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Router } from "@reach/router"
import { Link, navigate } from "gatsby"
import qs from "query-string"
import SpaceProfile from "../components/SpaceProfile"
import ProgressDots from "@components/ProgressDots"
import getSelectedOffices from "@utils/getSelectedOffices"
import Layout from "@layouts/Profile"
import getSelectedOfficesList from "@utils/getSelectedOfficesList"
import countWorkingDays from "@utils/countWorkingDays"
import { parse } from "date-fns"
import Seo from "@components/seo"
import getNextAvailableDate from "../utils/getNextAvailableDate"
import fetchHolidays from "@utils/fetchHolidays"
import notifyError from "@utils/notifyError"
import isUUID from "validator/es/lib/isUUID"
import { isSingleSpace, resTypes } from "@config"
import { setValues } from "../stores/ProfileStore/actions"
import { useDispatch, useSelector } from "react-redux"
import request from "../services/request"
import { get } from "lodash"

const ProfileWrapper = ({ spaceId, ...props }) => {
  const dispatch = useDispatch()
  const [invalidProfile, setIp] = useState(false)

  const location = useSelector(state => state.profile.location)
  const holidays = useSelector(state => state.profile.holidays)

  const [ph, setPh] = useState([])
  const [ch, setCh] = useState([])

  useEffect(() => {
    if (location.city) {
      const phForLocation = ph[location.city] || []
      dispatch(
        setValues({
          holidays: {
            ...holidays,
            [location.city]: [...phForLocation, ...ch],
          },
        }),
      )
    }
  }, [ph, ch, location.city])

  useEffect(() => {
    if (typeof window === "undefined" || !spaceId || location.id) {
      return
    }
    const params = qs.parse(props.location.search)

    let fetchedHolidays = []

    request("so/view", {
      id: process.env.GATSBY_CLIENT_UUID,
    })
      .then(res => {
        const opening_time = get(res, "soData.opening_time", "9:00 AM")
        const closing_time = get(res, "soData.closing_time", "5:00 PM")
        dispatch(
          setValues({ openingTime: opening_time, closingTime: closing_time }),
        )
      })
      .catch(e => {
        console.log(e)
      })

    fetchHolidays()
      .then(res => {
        fetchedHolidays = res
        setPh(res)
        dispatch(
          setValues({
            holidays: res,
          }),
        )
      })
      .then(() => {
        request(`view-sp`, {
          id: spaceId,
          onDemand: true,
          selectedOfficeIds: params.selected_office_ids || "",
        }).then(res => {
          if (!res.space) {
            setIp(true)
            notifyError("", res.space)
            return
          }
          const {
            stripe_connected_account_id = null,
            on_demand_emails = null,
            contact_email = null,
            contact_name = null,
            deleted_at = null,
            is_live = false,
            is_test = true,
            customHolidays = null,
            hero_image_url = null,
            common_images = [],
            tax_percentage: TAX_PERCENTAGE = 0,
          } = res.space
          if (
            !stripe_connected_account_id ||
            !on_demand_emails ||
            !contact_name ||
            deleted_at ||
            !is_live
          ) {
            // trying to view invalid profile
            setIp(true)
            notifyError("", res.space)
            return
          }

          if (
            is_test &&
            window.location.host !== "ws-light-staging.worksimply.com"
          ) {
            // trying to view test space in an invalid domain
            setIp(true)
            notifyError("", res.space)
            return
          }

          const ch = customHolidays
            ? customHolidays.map(({ date }) => {
                const dt = date.split("T")[0]
                if (!dt) return null
                return parse(dt, "yyyy-MM-dd", new Date())
              })
            : []

          setCh(ch)

          let temp_images = []
          if (hero_image_url) {
            temp_images.push(hero_image_url)
          }
          if (common_images && common_images.length > 0) {
            common_images.forEach(image => temp_images.push(image.url))
          }

          const startDate = params.start_date || null
          const endDate = params.end_date || null
          const startTime = params.start_time || null
          const endTime = params.end_time || null

          const nextAvailableDate = getNextAvailableDate(res.space.timezone)

          dispatch(
            setValues({
              location: res.space,
              HST: TAX_PERCENTAGE,
              TAX_PERCENTAGE,
              TAX_LABEL: res.space.tax_label,
              gallery: temp_images,
              queryParams: params,
              teamSize: Number(params.team_size) || 0,
              selectedOptionsCategorized: getSelectedOffices(res.space),
              noOptionsAvailable: isSingleSpace,
              selectedOptionsList: getSelectedOfficesList(res.space),
              selectedOption: getSelectedOfficesList(res.space)[0] || {
                id: "",
              },
              isPerDayOnDemand: params.on_demand_type !== "Hourly",
              reservation_type: params.res_type || "on-demand-offices",
              reservationType:
                resTypes.find(type => type.slug === params.res_type) ||
                resTypes[0],
              itemUnit:
                params.res_type === "on-demand-offices"
                  ? params.on_demand_type === "Hourly"
                    ? ["hour", "hours"]
                    : ["day", "days"]
                  : ["pass", "passes"],
              startDate: startDate
                ? parse(startDate, "yyyy-MM-dd", new Date())
                : null,
              endDate: endDate
                ? parse(endDate, "yyyy-MM-dd", new Date())
                : null,
              startTime: startTime
                ? parse(
                    `${params.start_date} ${startTime.toLowerCase()}`,
                    "yyyy-MM-dd hh:mm a",
                    new Date(),
                  )
                : "",
              endTime: endTime
                ? parse(
                    `${params.start_date} ${endTime.toLowerCase()}`,
                    "yyyy-MM-dd hh:mm a",
                    new Date(),
                  )
                : "",
              days: countWorkingDays(
                startDate ? new Date(startDate) : null,
                endDate ? new Date(endDate) : null,
                fetchedHolidays[location.city] || [],
              ),
              minDate: nextAvailableDate,
            }),
          )
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId])

  return (
    <Layout footer={!!location.id}>
      <Seo
        title={
          location.space_name
            ? `Book on-demand offices | ${location.space_name}`
            : "Book on-demand offices"
        }
        description={location.space_title || "Book on-demand offices"}
      />
      {invalidProfile ? (
        <InvalidProfile />
      ) : (
        <>{!location.id ? <ProgressDots active /> : <SpaceProfile />}</>
      )}
    </Layout>
  )
}

const RedirectResults = () => {
  // TODO redirect to 404
  if (typeof window !== "undefined") {
    navigate("/")
  }

  return null
}

const SpacePage = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = qs.parse(window.location.search)
      if (
        process.env.GATSBY_SPACE_UUID &&
        isUUID(process.env.GATSBY_SPACE_UUID)
      ) {
        // display the content
      } else if (!params || !params.res_type) {
        navigate(
          `${window.location.pathname}?res_type=${resTypes[0].slug}&on_demand_type=Daily`,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <RedirectResults path="/space" />
      <ProfileWrapper path="/space/:spaceId" />
    </Router>
  )
}

export default SpacePage

ProfileWrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  location: PropTypes.object,
  path: PropTypes.string,
  spaceId: PropTypes.string,
  selectedSpaceType: PropTypes.string,
  selectedSpaceId: PropTypes.string,
}

const InvalidProfile = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
      }}
    >
      <p>
        This profile is not yet available for reservations. Please try again
        later.
      </p>
      <Link to={"/"} className={"button primary mt-4"}>
        Go to home
      </Link>
    </div>
  )
}
