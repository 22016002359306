import React from "react"
import * as styles from "./index.module.less"

const ConditionsList = ({ list = [] }) => {
  if (list.length === 0) return null
  return (
    <ul className={styles.list}>
      {list.map((item, idx) => (
        <li key={idx}>{item}</li>
      ))}
    </ul>
  )
}

export default ConditionsList
