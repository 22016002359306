import React, { useState } from "react"
import useOptimizedImages from "@utils/useOptimizedImages"
import LightBox from "react-image-lightbox"
import ProgressDots from "@components/ProgressDots"
import imageOptimizer from "../../../services/imageOptimizer"
import "./index.less"
import { useSelector } from "react-redux"

const Img = ({ src, ...rest }) => {
  const newSrc = useOptimizedImages(src, 800, 800, 800, 800)
  return <img src={newSrc} {...rest} alt={""} />
}

const SpaceProfile = () => {
  const gallery = useSelector(state => state.profile.gallery)

  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const [loadedImages, setLoadedImages] = useState(0)

  const setLoaded = () => {
    setLoadedImages(loadedImages + 1)
  }

  const isReady = loadedImages >= (gallery.length > 4 ? 5 : 1)

  return (
    <div>
      <div className="container">
        <div
          className={`space-profile-image-grid ${
            isReady ? "loaded" : "loading"
          }`}
        >
          <div className="row">
            <div className="col-sm-6 d-flex">
              <div className="image-zoom-container first-image">
                <div
                  className="inner"
                  onClick={() => {
                    setIsOpen(true)
                    setPhotoIndex(0)
                  }}
                >
                  <Img
                    src={gallery[0]}
                    alt=""
                    className={"main-image"}
                    onLoad={setLoaded}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row">
                {gallery.slice(1, 5).map((image, idx) => {
                  return (
                    <div className="col-6" key={idx}>
                      <div className={`image-zoom-container secondary-image`}>
                        <div
                          className="inner"
                          onClick={() => {
                            setIsOpen(true)
                            setPhotoIndex(idx + 1)
                          }}
                        >
                          <Img
                            src={image}
                            alt=""
                            className={`img-` + idx}
                            onLoad={setLoaded}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {!isReady && <ProgressDots active />}

            <div className={`col-sm-12 all-button`}>
              <button
                className="button secondary"
                onClick={() => {
                  setIsOpen(true)
                  setPhotoIndex(0)
                }}
              >
                See all photos
              </button>
            </div>

            {isOpen && (
              <LightBox
                enableZoom={false}
                mainSrc={imageOptimizer(gallery[photoIndex], 1600)}
                nextSrc={gallery[(photoIndex + 1) % gallery.length]}
                prevSrc={
                  gallery[(photoIndex + gallery.length - 1) % gallery.length]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + gallery.length - 1) % gallery.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % gallery.length)
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpaceProfile
