import React, { useEffect } from "react"
import { setValues } from "../../../stores/ProfileStore/actions"
import nl2br from "../../../utils/nl2br"
import * as styles from "./index.module.less"
import filterSpacesForSingleSpace from "../../../utils/filterSpacesForSingleSpace"
import { isSingleSpace } from "../../../utils/config"
import getDailyPriceRange from "../../../utils/getDailyPriceRange"
import { useDispatch, useSelector } from "react-redux"
// import space from "../../../pages/space"

const SpaceSummary = () => {
  const location = useSelector(state => state.profile.location)
  const singleSpaceTeamSize = useSelector(
    state => state.profile.singleSpaceTeamSize
  )

  return (
    <>
      <div className={`container no-padding ${styles.space_summary_wrapper}`}>
        <div className="row">
          <div className="col-xl-11">
            <h2 className={styles.space_profile_title}>
              {location.space_title}
            </h2>
            <Summary singleSpaceTeamSize={singleSpaceTeamSize} />
            <p className={"my-0"}>{nl2br(location.space_description)}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SpaceSummary

export const Summary = ({ reservationPage = false }) => {
  const dispatch = useDispatch()

  const location = useSelector(state => state.profile.location)
  const queryParams = useSelector(state => state.profile.queryParams)
  const selectedOptionsList = useSelector(
    state => state.profile.selectedOptionsList
  )
  const singleSpaceTeamSize = useSelector(
    state => state.profile.singleSpaceTeamSize
  )
  const startDate = useSelector(state => state.profile.startDate)
  const endDate = useSelector(state => state.profile.endDate)

  useEffect(() => {
    if (!isSingleSpace) return
    // filter the spaces based on the team size
    if (!singleSpaceTeamSize || !endDate || !startDate) return
    dispatch(
      setValues({
        noOptionsAvailable: true,
        selectedOptionsList: [],
        selectedOption: { id: "" },
        selectedOptionsCategorized: [],
      })
    )
    const filteredSpaces = filterSpacesForSingleSpace(
      location,
      singleSpaceTeamSize,
      startDate,
      endDate
    )
    let final_list = []
    filteredSpaces.forEach(office => {
      const existing = final_list.find(
        item => item.floor_id === office.floor_id
      )
      if (!existing) {
        final_list.push({
          floor_id: office.floor_id,
          map_background_url: office.map_background_url,
          map_svg_url: office.map_svg_url,
          items: [],
        })
      }
    })

    final_list.forEach(office => {
      filteredSpaces.forEach(el => {
        if (el.floor_id === office.floor_id) {
          office.items.push(el)
        }
      })
    })

    final_list.sort(function (a, b) {
      return a.price_per_day - b.price_per_day
    })
    // Need to set selectedOptionsCategorized value as well!
    setTimeout(() => {
      dispatch(
        setValues({
          noOptionsAvailable: filteredSpaces.length === 0,
          selectedOptionsList: filteredSpaces,
          selectedOption:
            filteredSpaces.length > 0 ? filteredSpaces[0] : { id: "" },
          selectedOptionsCategorized: final_list,
        })
      )
    }, 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleSpaceTeamSize, endDate])

  let displayTs = queryParams.seat_count || 0
  if (!displayTs && singleSpaceTeamSize) {
    const tsArray = singleSpaceTeamSize.split("-")
    displayTs = tsArray[tsArray.length - 1]
  }

  if (!singleSpaceTeamSize) return null

  return (
    <div
      className={`${styles.space_profile_summary} ${
        reservationPage ? styles.reservations_list : ""
      }`}
    >
      <ul>
        <li>
          <span>Private office</span>
          <span>Type</span>
        </li>
        <li>
          <span>
            {queryParams.seat_count || singleSpaceTeamSize
              ? `Up to ${displayTs}`
              : "Please select team size"}
          </span>
          <span>Seat Count</span>
        </li>

        {!reservationPage && (
          <>
            {singleSpaceTeamSize || queryParams.seat_count ? (
              <li>
                <span>{getDailyPriceRange(selectedOptionsList)}</span>
                <span>Price</span>
              </li>
            ) : (
              ""
            )}
          </>
        )}

        {!reservationPage &&
          (queryParams.selected_office_ids ||
            selectedOptionsList.length >= 0) && (
            <li>
              {queryParams.selected_office_ids ? (
                <span>
                  {queryParams.selected_office_ids.split(",").length >= 2
                    ? 2
                    : 1}
                </span>
              ) : (
                <>
                  <span>{selectedOptionsList.length}</span>
                </>
              )}

              <span>No. Options</span>
            </li>
          )}
      </ul>
    </div>
  )
}
