import React  from "react"

const WorkHausTempConsent = ({ wh1, setWh1, wh2, setWh2, wh3, setWh3 }) => {
    const params  = typeof window === 'undefined' ? new URLSearchParams() : new URLSearchParams(window.location.search || '')

    const type = params.get('res_type') === "day-passes" ? "day pass" : "day office"
  return (
    <div>
      <div className={`input-with-error-message mb-3`}>
        <label htmlFor="consent1" className={"form-label mb-0 cursor-pointer"}>
          <input
            type="checkbox"
            id={"consent1"}
            checked={wh1}
            onChange={e => {
              setWh1(e.target.checked)
            }}
          />{" "}
          I understand that my {type} is valid from 9:30 am to 5 pm on my
          scheduled date.
        </label>
      </div>
      <div className={`input-with-error-message mb-3`}>
        <label htmlFor="consent2" className={"form-label mb-0 cursor-pointer"}>
          <input
            type="checkbox"
            id={"consent2"}
            checked={wh2}
            onChange={e => {
              setWh2(e.target.checked)
            }}
          />{" "}
          I understand that meeting rooms are not included but may be booked
          separately at specific locations.
        </label>
      </div>
      <div className={`input-with-error-message`}>
        <label htmlFor="consent3" className={"form-label mb-0 cursor-pointer"}>
          <input
            type="checkbox"
            id={"consent3"}
            checked={wh3}
            onChange={e => {
              setWh3(e.target.checked)
            }}
          />{" "}
          I understand that I must follow Workhaus' Anti-Harassment policy while
          on site as found in the full{" "}
          <a
            href="https://workhaus.ca/day-pass-terms-conditions/"
            target={"_blank"}
          >
            Terms & Conditions.
          </a>
        </label>
      </div>
    </div>
  )
}

export default WorkHausTempConsent
