import React, { useEffect, useState } from "react"
import * as styles from "./index.module.less"
import StripeCard from "../../StripeCard"
import isEmail from "validator/lib/isEmail"
import Datepicker from "react-datepicker"
import SubmitButton from "../../../../SubmitButton"
import { v4 as uuid } from "uuid"
import { format, parse } from "date-fns"
import Input from "./Input"
import PhoneNumber, { isValidPhone } from "./PhoneNumber"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import uploadPdf from "./uploadPdf"
import notifyError from "../../../../../utils/notifyError"
import StripeLogo from "./stripe-logo.svg"
import { useDispatch, useSelector } from "react-redux"
import { setValues } from "../../../../../stores/ProfileStore/actions"
import request from "../../../../../services/request"
import getTimeSlots from "../../../../../utils/getTimeSlots"
import addNewDateForDayPasses from "../../../../../utils/addNewDateForDayPasses"
import { graphql, useStaticQuery } from "gatsby"
import WorkHausTempConsent from "./WorkHausTempConsent"
import maxDateForCalendar from "../../../../../utils/maxDateForCalendar"
import couponEnabled from "../../../../../utils/couponEnabled"
import isWorkhaus from "../../../../../utils/isWorkhaus"

const Index = ({ setReservationIdForThePdf = () => null }) => {
  const stripe = useStripe()
  const elements = useElements()

  const itemsFromBuild = useStaticQuery(graphql`
    query {
      soData {
        id
        opening_time
        closing_time
        name
        cancellation_deadline_in_hours
        legal_business_name
        address
        website_url
      }
    }
  `)

  const {
    opening_time: openingTime,
    closing_time: closingTime,
    cancellation_deadline_in_hours,
  } = itemsFromBuild.soData

  const [isDatepickerVisible, setIsDatepickerVisible] = useState(false)

  const dispatch = useDispatch()

  const user = useSelector(state => state.profile.user)
  const minDate = useSelector(state => state.profile.minDate)
  const location = useSelector(state => state.profile.location)
  const { name, email, phone, couponCode } = useSelector(
    state => state.profile.user,
  )
  const startDate = useSelector(state => state.profile.startDate)
  const endDate = useSelector(state => state.profile.endDate)
  const teamSize = useSelector(state => state.profile.teamSize)
  const startTime = useSelector(state => state.profile.startTime)
  const endTime = useSelector(state => state.profile.endTime)
  const tentativeArrivalTime = useSelector(
    state => state.profile.tentativeArrivalTime,
  )
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)
  const reservationType = useSelector(state => state.profile.reservationType)
  const reservation_type = useSelector(state => state.profile.reservation_type)
  const datesForDayPasses = useSelector(
    state => state.profile.datesForDayPasses,
  )
  const onDemandDaysHoursCount = useSelector(
    state => state.profile.onDemandDaysHoursCount,
  )
  const selectedOnDemandOption = useSelector(
    state => state.profile.selectedOnDemandOption,
  )
  const holidays = useSelector(state => state.profile.holidays)

  const on_demand_email =
    location.on_demand_emails && location.on_demand_emails.split(",")[0]

  useEffect(() => {
    window.addEventListener("click", () => setIsDatepickerVisible(false))
    return () => {
      window.removeEventListener("click", () => setIsDatepickerVisible(false))
    }
  }, [])

  const isOnDemandOffices = reservation_type === "on-demand-offices"

  const [reservationId, setReservationId] = useState(null)
  const [client_secret, setCs] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [timeError, setTimeError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [dateError, setDateError] = useState("")
  const [cardError, setCardError] = useState("")
  const [validCard, setValidCard] = useState(false)
  const [couponError, setCouponError] = useState("")

  const [validationError, setValidationError] = useState({})

  const [tempStartTime, setTempStartTime] = useState(
    startTime ? format(startTime, "hh:mm a") : "",
  )

  const [consent, setConsent] = useState(false)
  const [wh1, setWh1] = useState(false)
  const [wh2, setWh2] = useState(false)
  const [wh3, setWh3] = useState(false)
  const [consentError, setConsentError] = useState("")

  const [statusMessage, setStatusMessage] = useState("")
  const [progress, setProgress] = useState(false)

  useEffect(() => {
    if (isWorkhaus) {
      setConsent(wh1 && wh2 && wh3)
    }
  }, [wh1, wh2, wh3])

  const isValid =
    name &&
    email &&
    isEmail(email) &&
    phone &&
    isValidPhone(phone) &&
    startDate &&
    validCard &&
    consent

  const validateFields = () => {
    let activeErrors = true
    if (!validCard) setCardError("Please enter valid credit card details")
    if (!consent) setConsentError("Please agree to the terms")
    if (!name) setNameError("Name is required")
    if (!startDate) setDateError("Date is required")
    if (!email || !isEmail(email)) setEmailError("Please enter a valid email")
    if (!phone || !isValidPhone(phone))
      setPhoneError("Phone number is required")

    if (isValid) {
      activeErrors = false
    }

    return activeErrors
  }

  const handlePay = async () => {
    setValidationError({})
    if (validateFields()) return

    const newUUID = uuid()

    setReservationId(newUUID)

    setReservationIdForThePdf(newUUID)

    const newData =
      reservationType.slug === "day-passes"
        ? {
            validateBookings: true,
            id: newUUID,
            space_id: location.id,
            number_of_passes: teamSize,
            date:
              datesForDayPasses && datesForDayPasses.length > 0
                ? format(datesForDayPasses[0], "yyyy-MM-dd")
                : null,
            dates: datesForDayPasses
              .map(date => format(date, "yyyy-MM-dd"))
              .join(","),
            name,
            email,
            phone,
            coupon_code: couponCode || "",
          }
        : {
            id: newUUID,
            date: format(startDate, "yyyy-MM-dd"),
            endDate: isPerDayOnDemand
              ? format(endDate, "yyyy-MM-dd")
              : format(startDate, "yyyy-MM-dd"),
            time: tempStartTime || "N/A",
            departure_time:
              !isPerDayOnDemand && endTime
                ? format(endTime, "hh:mm a")
                : closingTime || "5:00 pm",
            days: onDemandDaysHoursCount,
            name: user.name,
            email: user.email,
            phone: user.phone,
            optionId: selectedOnDemandOption.id,
            spaceId: location.id,
            space: location,
            isDaily: isPerDayOnDemand,
            spaceLink:
              typeof window !== "undefined" ? window.location.href : "#",
            team_size: teamSize,
            coupon_code: couponCode || "",
          }

    setProgress(true)
    setStatusMessage("Reserving...")
    await request(reservationType.reservation_endpoint, {
      ...newData,
    })
      .then(res => {
        console.log(res)
        setProgress(false)
        setStatusMessage("")
        if (res.client_secret) {
          setCs(res.client_secret)
        } else if (res?.validationError) {
          setValidationError(res.validationError)
        } else {
          notifyError(
            `Failed to create the client_secret from Stripe in ${
              typeof window !== "undefined"
                ? window.location.href
                : process.env.GATSBY_CLIENT_NAME
            }`,
          )
        }
      })
      .catch(e => {
        setProgress(false)
        notifyError(
          `Failed to create the reservation and/or generate client_secret in ${
            typeof window !== "undefined"
              ? window.location.href
              : process.env.GATSBY_CLIENT_NAME
          }`,
        )
      })
  }

  useEffect(() => {
    if (!client_secret) return
    submitCardDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_secret])

  const submitCardDetails = async () => {
    setProgress(true)
    setStatusMessage("Setting up the payment details")
    const result = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name,
          email,
        },
      },
    })
    if (result.error) {
      console.log(result.error.message)
      setStatusMessage(result.error.message)
      setProgress(false)
      notifyError(
        `Failed confirm the card setup in ${
          typeof window !== "undefined"
            ? window.location.href
            : process.env.GATSBY_CLIENT_NAME
        } - ${result.error.message}`,
      )
    } else {
      await confirmReservation()
    }
  }

  const confirmReservation = async () => {
    setProgress(true)
    setStatusMessage("Creating confirmation PDF")
    const { pdfUrl, base64PDF } = await uploadPdf(reservationId)

    const data =
      reservationType.slug === "day-passes"
        ? {
            update: true,
            pdfUrl,
            base64PDF,
            reservationId,
            logo: `${process.env.GATSBY_CLIENT_SLUG}.png`,
            spaceLink:
              typeof window !== "undefined" ? window.location.href : "",
          }
        : {
            id: reservationId,
            pdfUrl,
            base64PDF,
            spaceId: location.id,
            space: location,
            date: format(startDate, "yyyy-MM-dd"),
            endDate: isPerDayOnDemand
              ? format(endDate, "yyyy-MM-dd")
              : format(startDate, "yyyy-MM-dd"),
            time: tempStartTime,
            departure_time:
              !isPerDayOnDemand && endTime
                ? format(endTime, "hh:mm a")
                : closingTime || "5:00 pm",
            days: onDemandDaysHoursCount,
            name: user.name,
            email: user.email,
            phone: user.phone,
            optionId: selectedOnDemandOption.id,
            senderEmail: on_demand_email,
            senderName: process.env.GATSBY_CLIENT_NAME,
            optionData: selectedOnDemandOption,
            isDaily: isPerDayOnDemand,
            onDemandEmail:
              process.env.GATSBY_STAGE === "production" &&
              location &&
              location.on_demand_emails
                ? location.on_demand_emails
                : user.email,
            logo: `${process.env.GATSBY_CLIENT_SLUG}.png`,
            replyEmail: on_demand_email,
            spaceLink:
              typeof window !== "undefined"
                ? window.location.href
                : "https://worksimply.com",
            instructions: location.on_demand_instructions
              ? location.on_demand_instructions
              : null,
          }

    setStatusMessage("Sending the confirmation email")
    await request(reservationType.reservation_endpoint, {
      ...data,
    })
      .then(res => {
        return res
      })
      .catch(e => {
        notifyError(
          `Failed to send the confirmation emails in ${
            typeof window !== "undefined"
              ? window.location.href
              : process.env.GATSBY_CLIENT_NAME
          }`,
        )
      })
    setStatusMessage("")
    setProgress(false)
    dispatch(setValues({ checkOutFormSuccess: true, pdfUrl }))
  }

  const error = value => {
    return value === "" ? "" : "error"
  }

  const handlePhoneChange = phone => {
    dispatch(setValues({ user: { ...user, phone } }))
  }

  let disabled =
    !name ||
    !email ||
    !phone ||
    !datesForDayPasses ||
    datesForDayPasses.length === 0 ||
    !consent
  if (reservation_type === "on-demand-offices" && isPerDayOnDemand) {
    disabled = !startDate || !name || !email || !phone || !tempStartTime
  }
  if (reservation_type === "on-demand-offices" && !isPerDayOnDemand) {
    disabled = !startDate || !name || !email || !phone
  }

  const setDatesForDayPasses = date => {
    if (!date) return
    dispatch(
      setValues({
        datesForDayPasses: addNewDateForDayPasses(datesForDayPasses, date),
      }),
    )
  }

  const validateCoupon = async () => {
    setCouponError("")
    if (!couponCode) {
      setStatusMessage("")
      dispatch(setValues({ couponDiscountPercentage: 0 }))
      setCouponError("")
      return
    }
    setProgress(true)
    setStatusMessage("Validating the coupon")
    const res = await request("validate-coupon", {
      coupon_code: couponCode,
      location_id: location.id,
    })
    const discount = res?.coupon?.discount_percentage || 0
    console.log(res?.coupon)
    if (res?.coupon?.status === "danger") {
      setCouponError(res?.coupon?.message)
      setStatusMessage("")
    } else {
      setCouponError("")
      setStatusMessage(res?.coupon?.message || "")
    }
    // validate the coupon and apply the discount
    dispatch(setValues({ couponDiscountPercentage: discount / 100 }))
    setProgress(false)
  }

  let showCouponField = couponEnabled

  if (
    process.env.GATSBY_CLIENT_UUID === "b2b1f09a-06d5-4491-a504-57f05a3e258c" &&
    reservationType.slug !== "day-passes"
  ) {
    showCouponField = false
  }

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className={`input-with-error-message ${error(nameError)}`}>
          <Input
            placeholder={"Name"}
            value={name}
            name={"name"}
            onChange={e => {
              dispatch(setValues({ user: { ...user, name: e.target.value } }))
              setNameError("")
            }}
            onBlur={e => setNameError(e.target.value ? "" : "Name is required")}
          />
          <span>{nameError}</span>
        </div>
      </div>
      <div className="col-sm-6">
        <div className={`input-with-error-message ${error(emailError)}`}>
          <Input
            placeholder={"Work email"}
            type={"email"}
            name={"email"}
            value={email}
            onChange={e => {
              dispatch(setValues({ user: { ...user, email: e.target.value } }))
              setEmailError("")
            }}
            onBlur={e => {
              if (e.target.value && isEmail(e.target.value)) {
                setEmailError("")
              } else if (e.target.value && !isEmail(e.target.value)) {
                setEmailError("Please enter a valid email.")
              } else {
                setEmailError("Email is required")
              }
            }}
          />
          <span>{emailError}</span>
        </div>
      </div>
      <div className="col-sm-6">
        <div className={`input-with-error-message ${error(phoneError)}`}>
          <PhoneNumber
            placeholder={"Phone number"}
            value={phone}
            name={"phone"}
            setPhone={handlePhoneChange}
            clearErrors={() => (phoneError ? setPhoneError("") : null)}
            onBlur={e =>
              setPhoneError(
                e.target.value && isValidPhone(e.target.value, true)
                  ? ""
                  : "Please enter a valid phone number",
              )
            }
          />
          <span>{phoneError}</span>
        </div>
      </div>
      {reservationType.slug === "day-passes" && (
        <div className="col-sm-6 position-relative">
          <div className={`input-with-error-message ${error(timeError)}`}>
            <Input
              placeholder={"Tentative Arrival Time"}
              value={tentativeArrivalTime}
              type={"select"}
              onChange={e => {
                dispatch(
                  setValues({
                    tentativeArrivalTime: e.target.value,
                  }),
                )
              }}
            >
              <option value="" disabled>
                Tentative Arrival Time
              </option>
              {getTimeSlots(
                openingTime,
                closingTime,
                format(new Date(), "yyyy-MM-dd"),
                30,
              ).map(time => (
                <option value={format(time, "hh:mm a")} key={time}>
                  {format(time, "hh:mm a")}
                </option>
              ))}
            </Input>
            <span>{timeError}</span>
          </div>
        </div>
      )}
      {reservationType.slug === "on-demand-offices" && isPerDayOnDemand && (
        <div className="col-sm-6 position-relative">
          <div className={`input-with-error-message ${error(timeError)}`}>
            <Input
              placeholder={"Arrival Time"}
              value={tempStartTime}
              type={"select"}
              onChange={e => {
                const date = format(startDate, "yyyy-MM-dd")
                const tz = format(startDate, "XXX")
                const startTimeStr = `${date} ${e.target.value} ${tz}`
                setTimeError("")
                setTempStartTime(e.target.value)
                const startTime = parse(
                  startTimeStr,
                  "yyyy-MM-dd hh:mm a XXX",
                  new Date(),
                )
                dispatch(
                  setValues({
                    startTime,
                  }),
                )
              }}
              onBlur={e =>
                setTimeError(e.target.value ? "" : "Arrival time is required")
              }
            >
              <option value="" disabled>
                Arrival Time
              </option>
              {getTimeSlots(
                openingTime,
                closingTime,
                format(new Date(), "yyyy-MM-dd"),
                30,
              ).map(time => (
                <option value={format(time, "hh:mm a")} key={time}>
                  {format(time, "hh:mm a")}
                </option>
              ))}
            </Input>
            <span>{timeError}</span>
          </div>
        </div>
      )}
      {reservationType.slug === "day-passes" && (
        <div className="col-sm-6 position-relative">
          <div className={`input-with-error-message ${error(dateError)}`}>
            <Input
              placeholder={"Drop-in Date"}
              value={
                datesForDayPasses && datesForDayPasses.length > 1
                  ? "Multiple Dates"
                  : datesForDayPasses.length > 0
                    ? format(datesForDayPasses[0], "MMMM d")
                    : "Please select"
              }
              onClick={e => {
                e.stopPropagation()
                setIsDatepickerVisible(true)
              }}
              className={styles.date_picker_input}
            />
            <span>{dateError}</span>
          </div>
          {isDatepickerVisible && (
            <div
              className={styles.date_picker_modal_wrapper}
              onClick={e => e.stopPropagation()}
            >
              <span className="datepicker-custom-help-text">
                Multiple days can be selected
              </span>
              <Datepicker
                selected={null}
                minDate={minDate}
                maxDate={maxDateForCalendar(
                  process.env.GATSBY_CLIENT_UUID || "",
                )}
                onChange={setDatesForDayPasses}
                inline
                excludeDates={holidays[location.city] || []}
                highlightDates={datesForDayPasses}
                className={"weekend-disabled"}
              />
              <button
                className={`button ${styles.select_dates_button}`}
                onClick={() => setIsDatepickerVisible(false)}
              >
                Select Dates
              </button>
              <div className={styles.footer_actions}>
                <button
                  onClick={() => dispatch(setValues({ datesForDayPasses: [] }))}
                >
                  Clear All
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {showCouponField && (
        <div className="col-sm-6">
          <div className={`input-with-error-message ${error(couponError)}`}>
            <Input
              placeholder={"Coupon Code"}
              type={"text"}
              name={"couponCode"}
              value={couponCode}
              onChange={e => {
                setCouponError("")
                const tempC = e.target.value || ""
                const couponCode = tempC.toUpperCase()
                dispatch(setValues({ user: { ...user, couponCode } }))
              }}
              onBlur={validateCoupon}
            />
            <span>{couponError}</span>
          </div>
        </div>
      )}
      <div className="col-sm-12">
        <div className={`input-with-error-message ${error(cardError)}`}>
          <StripeCard
            setValidCard={setValidCard}
            setCardError={setCardError}
            setStatusMessage={setStatusMessage}
          />
          <span>{cardError}</span>
        </div>
      </div>
      <div className="col-sm-12">
        <div className={`cancellation-policy ${styles.cancellation_policy}`}>
          <h4>Cancellation policy </h4>
          <p>
            Free cancellation up to {cancellation_deadline_in_hours || 24} hours
            prior to the booking
          </p>
        </div>

        {isWorkhaus ? (
          <WorkHausTempConsent
            wh1={wh1}
            setWh1={setWh1}
            wh2={wh2}
            setWh2={setWh2}
            wh3={wh3}
            setWh3={setWh3}
          />
        ) : (
          <div className={`input-with-error-message ${error(consentError)}`}>
            <label
              htmlFor="consent"
              className={"form-label mb-0 cursor-pointer"}
            >
              <input
                type="checkbox"
                id={"consent"}
                checked={consent}
                onChange={e => {
                  setConsent(e.target.checked)
                  setConsentError("")
                }}
              />{" "}
              By selecting the button below, I agree to the{" "}
              <a target={"_blank"} href={"/terms-of-use"} rel="noreferrer">
                Terms of Use
              </a>{" "}
              and Cancellation Policy. I also consent to be charged the
              displayed amount on the date of reservation.
            </label>
            <span>{consentError}</span>
          </div>
        )}
      </div>
      <div className="col-sm-12">
        <div className={styles.submit_container}>
          <SubmitButton
            onClick={handlePay}
            disabled={disabled}
            progress={progress}
            label={"Reserve now"}
          />
          {statusMessage && <p>{statusMessage}</p>}
          <img src={StripeLogo} alt="Powered by Stripe" />
        </div>

        {validationError && (
          <div className={styles.invalidDates}>
            <p className={"text-danger"}>{validationError.message}</p>
            <ul>
              {validationError.invalidDates?.map(date => {
                return (
                  <li key={date} className={"text-danger"}>
                    -<span className={"d-inline-block ms-2"}>{date}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default Index
