import React from "react"
import * as styles from "./index.module.less"

const LocationSummaryList = ({ data = [] }) => {
  return (
    <div className={styles.list_wrapper}>
      <ul>
        {data.map(({ label, value }, idx) => (
          <li key={idx}>
            <span>{value}</span>
            <span>{label}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LocationSummaryList
