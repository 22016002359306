import { isAfter, isBefore, isSameDay, parse } from "date-fns"

const alreadyReserved = (reservations, startDate, endDate) => {
  return !!reservations.find(res => {
    const t_start = parse(res.date.split("T")[0], "yyyy-MM-dd", new Date())
    const t_end = res.end_date
      ? parse(res.end_date.split("T")[0], "yyyy-MM-dd", new Date())
      : t_start

    const validStart = isSameDay(t_start, endDate) || isBefore(t_start, endDate)
    const validEnd = isSameDay(t_end, startDate) || isAfter(t_end, startDate)

    return validStart && validEnd
  })
}
export default alreadyReserved
