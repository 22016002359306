import React, { useEffect, useState } from "react"
import * as styles from "./index.module.less"
import currencyFormat from "@utils/currencyFormat"
import PriceSummaryTable from "@components/PriceSummaryTable"
import LocationSummaryList from "../../components/LocationSummaryList"
import { format, isSameDay } from "date-fns"
import Form from "./Form"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import TitleSection from "./TitleSection"
import ShadowBox from "@components/ShadowBox"
import { resTypes } from "@config"
import { navigate } from "gatsby"
import qs from "query-string"
import Pdf from "./PDF"
import BackIcon from "@images/icons/back.svg"
import CloseIcon from "@images/icons/close.svg"
import { useDispatch, useSelector } from "react-redux"
import { setValues } from "../../../../stores/ProfileStore/actions"

const Index = () => {
  const dispatch = useDispatch()

  const [reservationIdForThePdf, setReservationIdForThePdf] = useState("")

  const openingTime = useSelector(state => state.profile.openingTime)
  const closingTime = useSelector(state => state.profile.closingTime)

  const couponDiscount = useSelector(state => state.profile.couponDiscount)
  const couponDiscountPercentage = useSelector(
    state => state.profile.couponDiscountPercentage
  )

  const subtotal = useSelector(state => state.profile.subtotal)
  const total = useSelector(state => state.profile.total)
  const location = useSelector(state => state.profile.location)
  const itemUnit = useSelector(state => state.profile.itemUnit)
  const hst = useSelector(state => state.profile.hst)
  const startDate = useSelector(state => state.profile.startDate)
  const endDate = useSelector(state => state.profile.endDate)
  const pricePerItem = useSelector(state => state.profile.pricePerItem)
  const teamSize = useSelector(state => state.profile.teamSize)
  const checkoutPage = useSelector(state => state.profile.checkoutPage)
  const pdfUrl = useSelector(state => state.profile.pdfUrl)
  const startTime = useSelector(state => state.profile.startTime)
  const endTime = useSelector(state => state.profile.endTime)
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)
  const checkOutFormSuccess = useSelector(
    state => state.profile.checkOutFormSuccess
  )
  const reservation_type = useSelector(state => state.profile.reservation_type)
  const queryParams = useSelector(state => state.profile.queryParams)
  const onDemandDaysHoursCount = useSelector(
    state => state.profile.onDemandDaysHoursCount
  )
  const datesForDayPasses = useSelector(
    state => state.profile.datesForDayPasses
  )
  const TAX_PERCENTAGE = useSelector(state => state.profile.TAX_PERCENTAGE)
  const TAX_LABEL = useSelector(state => state.profile.TAX_LABEL)

  useEffect(() => {
    if (reservation_type === "on-demand-offices") return

    const numberOfDays = datesForDayPasses ? datesForDayPasses.length : 1
    const subtotal = location.price_per_day_pass * teamSize * numberOfDays

    const couponDiscount = subtotal * couponDiscountPercentage

    const total =
      location.price_per_day_pass *
      teamSize *
      numberOfDays *
      (1 - couponDiscountPercentage) *
      (1 + TAX_PERCENTAGE)

    const hst = total * TAX_PERCENTAGE

    dispatch(
      setValues({
        total,
        subtotal,
        hst,
        couponDiscount,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSize, datesForDayPasses, couponDiscountPercentage])

  useEffect(() => {
    if (reservation_type !== "on-demand-offices") return

    const newTotal =
      subtotal * (1 - couponDiscountPercentage) * (1 + TAX_PERCENTAGE)

    const couponDiscount = subtotal * couponDiscountPercentage

    dispatch(
      setValues({
        total: newTotal,
        hst: subtotal * (1 - couponDiscountPercentage) * TAX_PERCENTAGE,
        couponDiscount,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponDiscountPercentage])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scroll(0, 0)
    }
  }, [checkoutPage])

  const [stripePromise] = useState(
    loadStripe(process.env.GATSBY_STRIPE_PK, {
      stripeAccount:
        location.stripe_connected_account_id ||
        process.env.GATSBY_STRIPE_TEST_ACCOUNT_ID,
    })
  )

  const [checkInTime, setCheckInTime] = useState(
    `From ${openingTime || "9:00 am"}`
  )
  const [checkOutTime, setCheckOutTime] = useState(closingTime || "5:00 pm")

  const on_demand_email =
    location.on_demand_emails && location.on_demand_emails.split(",")[0]

  useEffect(() => {
    if (reservation_type === "on-demand-offices") {
      if (!isPerDayOnDemand) {
        if (startTime) setCheckInTime(format(startTime, "hh:mm a"))
        if (endTime) setCheckOutTime(format(endTime, "hh:mm a"))
      } else {
        if (startTime) setCheckInTime(format(startTime, "hh:mm a"))
      }
    }
  }, [reservation_type, isPerDayOnDemand, startTime, endTime])

  const back = data => {
    const newQueryParams = queryParams
    delete newQueryParams.express_checkout
    dispatch(setValues({ queryParams: newQueryParams, ...data }))
    navigate(`/space/${location.id}?${qs.stringify(newQueryParams)}`)
  }

  const [displayDate, setDisplayDate] = useState("")

  useEffect(() => {
    if (reservation_type === "on-demand-offices") {
      setDisplayDate(format(startDate, "MMMM d, yyyy"))
      if (startDate && endDate && !isSameDay(startDate, endDate)) {
        setDisplayDate(
          `${format(startDate, "MMM do, yyyy")} - ${format(
            endDate,
            "MMM do, yyyy"
          )}`
        )
      } else if (startDate) {
        setDisplayDate(format(startDate, "MMMM do, yyyy"))
      }
    } else {
      // day passes
      if (datesForDayPasses.length === 1) {
        setDisplayDate(format(datesForDayPasses[0], "MMMM d, yyyy"))
      } else if (datesForDayPasses.length > 0) {
        setDisplayDate("Multiple Dates")
      } else {
        setDisplayDate("Please Select")
      }
    }
  }, [startDate, reservation_type, datesForDayPasses])

  if (checkOutFormSuccess) {
    return (
      <div className={styles.success_page}>
        <div className={"container"}>
          <div className="row">
            <div className="col-lg-6">
              <h1 className={"my-0"}>Booking confirmed!</h1>
              <p className={"my-4 py-2"}>
                Please download the confirmation PDF and show it to the
                community manager upon arrival. If you have any questions please
                send us an email to {on_demand_email}
              </p>
              {pdfUrl && (
                <a
                  href={pdfUrl}
                  target={"_blank"}
                  rel={"noreferrer"}
                  className={"button primary"}
                >
                  Download confirmation PDF
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Elements stripe={stripePromise} options={{}}>
        <div className={styles.reservation_wrapper}>
          {!queryParams.express_checkout && (
            <div className={styles.header}>
              <div className="container">
                <button
                  className={styles.back_button}
                  onClick={() =>
                    back({ checkoutPage: false, backFromCheckoutPage: true })
                  }
                >
                  <img src={BackIcon} alt="" />
                </button>
                <button
                  className={styles.back_button}
                  onClick={() =>
                    back({
                      checkoutPage: false,
                      reservationStep: 1,
                      mobileOptionPicker: false,
                    })
                  }
                >
                  <img src={CloseIcon} alt="" />
                </button>
              </div>
            </div>
          )}

          <div className="container mb-5 pb-5">
            <div className="row">
              <div className="col-lg-8 pr-lg-5 mb-4">
                <TitleSection />
                <LocationSummaryList
                  data={[
                    {
                      label: "Type",
                      value: resTypes.find(
                        type => type.slug === reservation_type
                      ).name,
                    },
                    {
                      label: "Date",
                      value: displayDate,
                    },
                    { label: "Check-in Time", value: checkInTime },
                    { label: "Check-out Time", value: checkOutTime },
                  ]}
                />

                <div className={"d-lg-none"}>
                  <h3>Price details</h3>
                  <PriceSummaryTable
                    noBorders
                    dayPassesCounter
                    rows={[
                      {
                        label: `$${pricePerItem} x ${
                          queryParams.res_type !== "day-passes"
                            ? onDemandDaysHoursCount
                            : teamSize
                        } ${itemUnit[teamSize > 1 ? 1 : 0]}`,
                        value: `${currencyFormat(subtotal)}${customForDayPasses(
                          datesForDayPasses
                        )}`,
                        datesForDayPasses,
                      },
                      {
                        hidden: !couponDiscount,
                        label: `Coupon (${couponDiscountPercentage * 100}%)`,
                        value: `- ${currencyFormat(couponDiscount)}`,
                      },
                      {
                        hidden: !TAX_PERCENTAGE || !TAX_LABEL,
                        label: `${TAX_LABEL} (${TAX_PERCENTAGE * 100}%)`,
                        value: `${currencyFormat(hst)}`,
                      },
                      {
                        label: `Total`,
                        value: `${currencyFormat(total)}`,
                      },
                    ]}
                  />
                </div>

                <h3>Your details</h3>

                <Form setReservationIdForThePdf={setReservationIdForThePdf} />
              </div>
              <div className="col-lg-4 d-none d-lg-block">
                <ShadowBox>
                  <h4>Price details</h4>
                  <PriceSummaryTable
                    dayPassesCounter
                    rows={[
                      {
                        label: `$${pricePerItem} x ${
                          reservation_type === "on-demand-offices"
                            ? onDemandDaysHoursCount
                            : teamSize
                        } ${itemUnit[teamSize > 1 ? 1 : 0]}${customForDayPasses(
                          datesForDayPasses
                        )}`,
                        value: `${currencyFormat(subtotal)}`,
                        datesForDayPasses,
                      },
                      {
                        hidden: !couponDiscount,
                        label: `Coupon (${couponDiscountPercentage * 100}%)`,
                        value: `- ${currencyFormat(couponDiscount)}`,
                      },
                      {
                        hidden: !TAX_PERCENTAGE || !TAX_LABEL,
                        label: `${TAX_LABEL} (${TAX_PERCENTAGE * 100}%)`,
                        value: `${currencyFormat(hst)}`,
                      },
                      {
                        label: `Total`,
                        value: `${currencyFormat(total)}`,
                      },
                    ]}
                  />
                </ShadowBox>
              </div>
            </div>
          </div>
        </div>
      </Elements>
      <Pdf reservationIdForThePdf={reservationIdForThePdf} />
    </>
  )
}

export default Index

const customForDayPasses = dates => {
  const searchString =
    typeof window !== "undefined" ? window.location.search : ""
  const params = new URLSearchParams(searchString)

  const type = params.get("res_type")
  if (type !== "day-passes") {
    return ""
  }
  if (!dates || dates.length <= 1) return " x 1 day"
  return ` x ${dates.length} days`
}
