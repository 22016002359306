import React from "react"
import replace from "../../../utils/replace"
import * as styles from "./index.module.less"
import { useSelector } from "react-redux"

const SpaceProfile = () => {
  const location = useSelector(state => state.profile.location)

  return (
    <div className="container">
      <h1 className={styles.title}>{replace(location.space_name)}</h1>
    </div>
  )
}

export default SpaceProfile
